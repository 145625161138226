let baseUrl = ''
const env = process.env
let casLoginUrl = ''
let casLogOutUrl = ''
let selfUrl = ''
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
// 激光充值
let jgczUrl = ''
// 臻陆影购
let zzygUrl = ''
if (env.VUE_APP_URL === 'development') {
  baseUrl = 'http://127.0.0.1:81'
  casLoginUrl = 'http://127.0.0.1:81/cas/mallLogin'
  selfUrl = 'http://127.0.0.1:1888'
  casLogOutUrl = `http://127.0.0.1:81/cas/logOut?service=${selfUrl}`
  // baseUrl = 'http://218.241.209.227:18080/liao'
  // casLoginUrl = 'http://218.241.209.227:18080/liao/cas/mallLogin'
  // selfUrl = 'http://218.241.209.227:18080'
  // casLogOutUrl = `http://218.241.209.227:18080/liao/cas/logOut?service=${selfUrl}`

  jgczUrl = 'http://139.219.0.117:6050/cas/login?service=http://218.241.209.227:8087/'
  zzygUrl = 'https://apptest.cineappo.com/cms/cinema/#/filmList'
} else if (env.VUE_APP_URL === 'production') {
  // baseUrl = 'http://127.0.0.1:81'
  // casLoginUrl = 'http://127.0.0.1:81/cas/mallLogin'
  // selfUrl = 'http://127.0.0.1:1888'
  // casLogOutUrl = `http://127.0.0.1:81/cas/logOut?service=${selfUrl}`
  baseUrl = 'https://jinbizx.com:81'
  casLoginUrl = 'https://jinbizx.com:81/cas/mallLogin'
  selfUrl = 'http://jinbizx.com/mall'
  selfUrl = 'https://jinbizx.com/mall'
  casLogOutUrl = `https://jinbizx.com:81/cas/logOut?service=${selfUrl}`

  jgczUrl = 'http://139.219.0.117:6050/cas/login?service=http://218.241.209.227:8087/'
  zzygUrl = 'https://apptest.cineappo.com/cms/cinema/#/filmList'
} else if (env.VUE_APP_URL === 'last') {
  baseUrl = 'https://www.xiniumall.com/liao'
  casLoginUrl = 'https://www.xiniumall.com/liao/cas/mallLogin'
  selfUrl = 'https://www.xiniumall.com'
  casLogOutUrl = `https://www.xiniumall.com/liao/cas/logOut?service=${selfUrl}`
  jgczUrl = 'https://uc.cineappo.com/cas/login?service=https://recharge.cineappo.com/'
  zzygUrl = 'https://app.filmland.cn/cms/cinema/#/filmList'
} else if (env.VUE_APP_URL === 'production') {
  // baseUrl = 'http://jinbizx.com:81'
  // casLoginUrl = 'http://jinbizx.com:81/cas/mallLogin'
  // selfUrl = 'http://jinbizx.com/mall'
  // casLogOutUrl = `http://jinbizx.com:81/cas/logOut?service=${selfUrl}`
  baseUrl = 'https://www.xiniumall.com/liao'
  casLoginUrl = 'https://www.xiniumall.com/liao/cas/mallLogin'
  selfUrl = 'https://www.xiniumall.com'
  casLogOutUrl = `https://www.xiniumall.com/liao/cas/logOut?service=${selfUrl}`
  jgczUrl = 'https://uc.cineappo.com/cas/login?service=https://recharge.cineappo.com/'
  zzygUrl = 'https://app.filmland.cn/cms/cinema/#/filmList'
} else if (env.VUE_APP_URL === 'test') {
  baseUrl = 'http://218.241.209.227:18080/liao'
  casLoginUrl = 'http://218.241.209.227:18080/liao/cas/mallLogin'
  selfUrl = 'http://218.241.209.227:18080'
  casLogOutUrl = `http://218.241.209.227:18080/liao/cas/logOut?service=${selfUrl}`

  jgczUrl = 'http://139.219.0.117:6050/cas/login?service=http://218.241.209.227:8087/'
  zzygUrl = 'https://apptest.cineappo.com/cms/cinema/#/filmList'
} else if (env.VUE_APP_URL === 'other') {
  baseUrl = 'http://218.241.209.227:18080/liao'
  casLoginUrl = 'http://218.241.209.227:18080/liao/cas/mallLogin'
  selfUrl = 'http://218.241.209.227:18080'
  casLogOutUrl = `http://218.241.209.227:18080/liao/cas/logOut?service=${selfUrl}`

  jgczUrl = 'http://139.219.0.117:6050/cas/login?service=http://218.241.209.227:8087/'
  zzygUrl = 'https://apptest.cineappo.com/cms/cinema/#/filmList'
} else {
  // baseUrl = 'http://127.0.0.1:81'
  // casLoginUrl = 'http://127.0.0.1:81/cas/mallLogin'
  // selfUrl = 'http://127.0.0.1:1888'
  // casLogOutUrl = `http://127.0.0.1:81/cas/logOut?service=${selfUrl}`
  // ---
  // baseUrl = 'http://218.241.209.227:18080/liao'
  // casLoginUrl = 'http://218.241.209.227:18080/liao/cas/mallLogin'
  // selfUrl = 'http://218.241.209.227:18080'
  // casLogOutUrl = `http://218.241.209.227:18080/liao/cas/logOut?service=${selfUrl}`

  baseUrl = 'https://www.xiniumall.com/liao'
  casLoginUrl = 'https://www.xiniumall.com/liao/cas/mallLogin'
  selfUrl = 'https://www.xiniumall.com'
  casLogOutUrl = `https://www.xiniumall.com/liao/cas/logOut?service=${selfUrl}`

  jgczUrl = 'http://139.219.0.117:6050/cas/login?service=http://218.241.209.227:8087/'
  zzygUrl = 'https://apptest.cineappo.com/cms/cinema/#/filmList'
}
export {
  baseUrl,
  env,
  casLoginUrl,
  casLogOutUrl,
  jgczUrl,
  zzygUrl,
  selfUrl
}
